import React, { useState, useEffect } from "react";
import { collection, query, orderBy, onSnapshot } from "firebase/firestore";
import { db } from "./firebase";
import { ListGroup, Modal, Button, Navbar } from 'react-bootstrap';



function UserList({ agencyAccessCode }) {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [settingsModalShow, setSettingsModalShow] = useState(false);
  const [enableSound, setEnableSound] = useState(false);
  const [settings, setSettings] = useState({
    "EMD": {}, "EFD": {},
  });

  const quadrants = ["09", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "32", "W1", "W2", "W3", "W4", "W5", "W6", "W9", "U9", "KC"];
  const alertSound = new Audio('/alertSound.mp3');

  useEffect(() => {
    const q = query(
      collection(db, "data"),
      orderBy("createDateTime", "desc")
    );

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const newDocs = querySnapshot.docChanges().filter((change) => change.type === "added");
      newDocs.forEach((doc) => {
        const data = doc.doc.data();
        const callType = data.callType;
        const location = data.location;
        const quadrant = data.quadrant;

        if (["EMD", "EFD"].includes(callType) && enableSound) {
          const selectedQuadrants = Object.keys(settings[callType]).filter((q) => settings[callType][q]);
          const matchedQuadrant = selectedQuadrants.find((q) => q.slice(0, 2) === quadrant.slice(0, 2));

          if (matchedQuadrant) {
            alertSound.play();
            generateSpeech(callType, location);
          }
        }
      });
      setUsers(querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    });


    return () => unsubscribe();
  }, [agencyAccessCode, enableSound, settings]);

  const generateSpeech = async (callType, location) => {
    try {
      const response = await fetch('https://us-central1-nc911mspprd.cloudfunctions.net/generateSpeech', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ callType, location }),
      });

      if (!response.ok) {
        throw new Error('Failed to generate speech');

      }

      const { audioSource } = await response.json();
      const speechAudio = new Audio(audioSource);

      // Play TTS after sound playback is complete
      alertSound.onended = () => {
        speechAudio.play();
      };

      alertSound.play();
    } catch (error) {
      console.error('Error generating speech:', error);
    }
  };

  const openPopup = (user) => {
    setSelectedUser(user);
  };

  const closePopup = () => {
    setSelectedUser(null);
  };

  const handleSoundToggle = () => {
    setEnableSound((prevEnableSound) => !prevEnableSound);
  };

  const handleQuadrantToggle = (callType, quadrant) => {
    setSettings((prevSettings) => ({
      ...prevSettings,
      [callType]: {
        ...prevSettings[callType],
        [quadrant]: !prevSettings[callType]?.[quadrant], // Toggle the value of the quadrant
      },
    }));
  };

  const handleToggleAll = (callType, toggleValue) => {
    setSettings((prevSettings) => ({
      ...prevSettings,
      [callType]: quadrants.reduce((acc, quadrant) => {
        acc[quadrant] = toggleValue;
        return acc;
      }, {}),
    }));
  };
  

  return (
    <div>
    <Navbar bg="light">
  <Navbar.Collapse className="justify-content-end">
    <Button variant={enableSound ? "primary" : "secondary"} onClick={handleSoundToggle} className="mr-2">
      {enableSound ? "Disable Sound Alerts" : "Enable Sound Alerts"}
    </Button>
    <Button onClick={() => setSettingsModalShow(true)} className="btn-spacing">Filter Stations</Button>
  </Navbar.Collapse>
</Navbar>



      <Modal show={settingsModalShow} onHide={() => setSettingsModalShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Filter Stations</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {["EMD", "EFD"].map((callType) => (
            <div key={callType}>
              <h5>{callType}</h5>
              <div className="grid">
                <div className="text-center mt-3">
                  <Button
                    variant="primary"
                    onClick={() => handleToggleAll(callType, true)}
                    className="mr-2"
                  >
                    All On
                  </Button>
                  <Button
                    variant="secondary"
                    onClick={() => handleToggleAll(callType, false)}
                  >
                    All Off
                  </Button>
                </div>
                {quadrants.map((quadrant) => (
                  <Button
                    key={quadrant}
                    variant={settings[callType]?.[quadrant] ? "primary" : "secondary"}
                    onClick={() => handleQuadrantToggle(callType, quadrant)}
                  >
                    {quadrant}
                  </Button>
                ))}
              </div>
            </div>
          ))}
        </Modal.Body>
      </Modal>

      <ListGroup>
        {users.map((user) => (
          <ListGroup.Item key={user.id} className="d-flex justify-content-between align-items-center" onClick={() => openPopup(user)}>
            <div>
              {user.callType}
              <div>{user.location}</div>
            </div>
            <div>
              <div>{user.quadrant}</div>
            </div>
          </ListGroup.Item>
        ))}
      </ListGroup>

      <Modal show={selectedUser !== null} onHide={closePopup}>
        <Modal.Header closeButton>
          <Modal.Title>Narrative</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedUser?.narratives
            ?.sort((a, b) => b.enteredDateTime - a.enteredDateTime)
            ?.map((narrativeObj, index) => (
              <div key={index}>{narrativeObj.narrative}</div>
            ))}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closePopup}>Close</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default UserList;
